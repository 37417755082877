<template>
  <div>
    <van-nav-bar title="提交订单" left-arrow @click-left="onClickLeft" />
    <div class="content bg-white">
      <div class="shopTitle">王振黄焖鸡</div>
      <template v-if="list">
        <shopOrderDetail v-for="item in list" :key="item.productCode" :obj="item" />
      </template>

      <div class="sum">
        <van-row type="flex" justify="space-between" align="center">
          <van-col span="12" style="text-align: left;">小计</van-col>
          <van-col span="12" style="text-align: right"><span>￥{{totalAmount}}</span></van-col>
        </van-row>
      </div>

    </div>
    <div class="goToOrder">
      <!-- 两端对齐 -->
      <van-row type="flex" justify="space-between" align="center">
        <van-col span="12" style="text-align: left; font-size: 1rem">￥ <span
            style="color:#ed5d54; font-size: 1.25rem;">{{totalAmount}}</span></van-col>
        <van-col span="12" style="text-align: right">
          <div class="orderBtn" @click="goOrder">去下单</div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import shopOrderDetail from "../../components/productToDetail.vue";
export default {
  components: {
    shopOrderDetail,
  },
  data () {
    return {
      list: [],
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1);
    },

    goOrder () {
      sessionStorage.removeItem('orderList');
    }
  },

  computed: {
    totalAmount () {
      let temp = 0
      this.list.forEach(item => {
        temp += parseInt(item.num) * item.normalPrice
      })
      return temp
    }
  },

  created () {
    let temp = sessionStorage.getItem('orderList')
    this.list = temp ? JSON.parse(temp) : []
  }
};
</script>

<style lang="less" scoped>
.content {
  margin: 1.25rem;
  border-radius: 0.625rem;
  text-align: left;
  height: calc(100vh - 10rem);
  overflow: scroll;
}
.shopTitle {
  border-bottom: 1px dotted #ed5d54;
  font-size: 1rem;
  margin: 0.625rem;
  font-weight: bold;
  padding: 1rem 0;
}
.goToOrder {
  height: 2.8125rem;
  // line-height: 2.8125rem;
  position: fixed;
  bottom: 0.5rem;
  left: 1rem;
  right: 1rem;
  background: #333;
  color: #fff;
  border-radius: 2rem;
  padding-left: 1rem;
}
.orderBtn {
  height: 2.8125rem;
  line-height: 2.8125rem;
  width: 50%;
  color: #fff;
  background: #ed5d54;
  // padding: 0 0.625rem;
  text-align: center;
  display: inline-block;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
.sum {
  padding: 1rem;
  border-top: 1px dotted #eee;
  font-size: 1rem;
  margin-top: 1rem;
}
</style>