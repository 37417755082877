<template>
  <div>
    <div class="flex bd">
      <div class="image" :style="`background:url(${obj.smallImg||productImg});background-size: cover;`"></div>
      <div class="content flex flex-direction justify-between">
        <div class="font-s14">{{obj.productName}}</div>
        <div class="font-s12" style="color:#999">￥{{obj.normalPrice}}</div>
        <div class="amout"> x{{obj.num}}</div>
      </div>
      <div class="btn-d flex justify-end">
        ￥{{totalAmount}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    totalAmount () {
      return this.obj.normalPrice * this.obj.num
    }
  }
}
</script>

<style lang="less" scoped>
.bd {
  height: 5rem;
  border-bottom: 0.0625rem solid #fafafa;
  padding: 0 1.25rem;
  .image {
    background: #000;
    height: 4.375rem;
    width: 4.375rem;
    border-radius: 0.3125rem;
    margin-right: 0.3125rem;
  }
  .content {
    height: 4.375rem;
    text-align: left;
    flex: 1;
    .amout {
      color: #999;
    }
  }
  .btn-d {
    text-align: right;
    .btn {
      width: 0.9375rem;
      height: 0.9375rem;
      line-height: 0.9375rem;
      text-align: center;
      border-radius: 50%;
    }
    .btn-a {
      color: #fff;
      background: #ee0a24;
    }
    .btn-r {
      color: #ee0a24;
      background: #fff;
      border: 0.0625rem solid #ee0a24;
    }
  }
}
</style>